<template>
  <footer class="main-footer">
    <div class="main-footer__inner">
      <ul class="info-list">
        <li>대표이사: 이진희</li>
        <li>주소: 경남 창원시 의창구 평산로 33, 신화더플렉스시티 701호,702호</li>
        <li>대표전화: 070-7931-4525</li>
        <li>팩스: 0504-983-4524</li>
      </ul>
      <ul class="info-list">
        <li>이메일: cs@teampl100.com</li>
        <li>개인정보보호 책임자: 이진희</li>
        <li>사업자등록번호: 894-86-00807</li>
      </ul>
      <div class="copyright">
        ⓒ {{ getCurrentYear }}: Teampl100 Co., Ltd. All Rights Reserved.
      </div>
      <ul class="terms-list">
        <li>
          <a href="javascript:void(0)" @click="handleVisibleTerms()">서비스 이용약관</a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="handleVisiblePrivacyTerms()">개인정보 처리방침</a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="handlePcVersion()">PC 버전</a>
        </li>
      </ul>
    </div>
    <el-dialog v-model="termsVisible" width="900px">
      <Terms />
    </el-dialog>
    <el-dialog v-model="privacyTermsVisible" width="900px">
      <TermsPrivacy />
    </el-dialog>
  </footer>
</template>

<script setup lang="ts">
const termsVisible = ref(false);

const privacyTermsVisible = ref(false);

// 현재 연도를 반환
const getCurrentYear = computed(() => new Date().getFullYear());

const mobileCookie = (() => {
  const options: Record<string, string> = {
    path: '/',
  };
  if (process.env.NODE_ENV === 'production') {
    options.domain = '.jajakjajak.com';
  }
  return useCookie('MOBILE', options);
})();

function handleVisibleTerms() {
  termsVisible.value = !termsVisible.value;
}

function handleVisiblePrivacyTerms() {
  privacyTermsVisible.value = !privacyTermsVisible.value;
}

function handlePcVersion() {
  mobileCookie.value = '0';
  location.href = 'https://www.jajakjajak.com';
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/layouts/main/footer.scss';
</style>
