<template>
  <header class="main-header">
    <div class="main-header__logo" @click="$router.push({ name: 'index' })">
      <img src="@/assets/img/logo.svg">
    </div>
    <ul class="main-header__tool">
      <li v-if="authStore.isLogin">
        <NuxtLink :to="{ name: 'room' }">시작하기</NuxtLink>
      </li>
      <li>
        <NuxtLink :to="{ name: 'contact' }">가격안내</NuxtLink>
      </li>
      <li>
        <a href="https://teampl100.notion.site/ae318e93c65a48fda2055d202b19ab8f" target="_blank">제품안내</a>
      </li>
      <li v-if="authStore.isLogin">
        <a href="javascript:void(0)" @click="handleLogout()">로그아웃</a>
      </li>
      <slot v-else>
        <li>
          <NuxtLink :to="{ name: 'join' }">회원가입</NuxtLink>
        </li>
        <li>
          <NuxtLink :to="{ name: 'login' }">로그인</NuxtLink>
        </li>
      </slot>
    </ul>
  </header>
</template>

<script setup lang="ts">
// import { useJajakToken } from '~/composables/token';
import { userLogout } from '@/composables/php/auth';

const authStore = useAuthStore();

// const accessToken = useJajakToken();

// function handleLogout() {
//   accessToken.value = null;
//   authStore.resetUser();
//   useRouter().push({ name : 'index' });
// }

async function handleLogout() {
  await userLogout().then(() => {
    authStore.resetUser();
    useRouter().push({ name : 'index' });
  }).catch((e) => {
    const message = e.response && e.response.data && e.response.data.message || e.message;
      ElMessage.error(message || '알 수 없는 오류가 발생하였습니다.')
  });
}

</script>

<style lang="scss" scoped>
@import '@/assets/css/layouts/main/header.scss';
</style>
